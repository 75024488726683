import React from 'react';
import { Col, Row } from 'reactstrap';

import backgroundImage from '../../assets/img/gobimg2.jpg';

function Footer() {
  return (
    <>
      <Row
        className="align-items-center mt-5 p-2"
        style={{
          height: '600px',
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Col>
          <Row>
            <Col>
              <div className="d-flex flex-column justify-content-center">
                <h1 className="font-weight-700 text-shadow text-white text-center title-xl mt-5">
                  SGAS, Av. W/5, Quadra 913, Conjunto H
                  <br />
                  Brasília, DistritoFederal, 70390-130
                </h1>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark">
        <Col>
          <div className="d-flex text-white text-center justify-content-center py-4 px-2">
            Criado por AthosLabs - Comunicação Criativa - Todos os
            direitos reservados.
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
