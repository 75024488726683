import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function PresidenteAgenda() {
  const { setLoading } = useContext(AppContext);
  const [palavras, setPalavras] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/presidentePalavras?page=${page}`);
        // console.log(data);
        setPalavras(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setPalavras([]);
  }, [page]);

  const handleShowModalImagem = useCallback((imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  }, []);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="PALAVRA DO <br /> PRESIDENTE" />
      <Container className="mt-5">
        {palavras.length > 0 ? (
          <Row>
            {palavras.map((p) => (
              <Col key={p._id} xs={12} sm={6} md={4}>
                <Card className="shadow">
                  <div
                    style={{
                      height: '200px',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${p.image_url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleShowModalImagem(p.image_url)}
                    onKeyDown={() => handleShowModalImagem(p.image_url)}
                    onKeyUp={() => handleShowModalImagem(p.image_url)}
                    role="presentation"
                  />
                  <CardBody>
                    <div className="d-flex flex-column justify-content-center">
                      <span>
                        {format(new Date(p.date), 'dd/MM/yyyy', ptBR)}
                      </span>
                      <span>{p.description}</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">
                  Nenhuma palavra do presidente
                </strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        className="position-relative"
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        centered
      >
        <Button
          className="position-absolute top-3 right-3"
          color="link"
          onClick={() => setShowModal(false)}
        >
          <i className="fas fa-times" style={{ fontSize: '1rem' }} />
          <p
            style={{
              fontSize: '0.9rem',
              lineHeight: '0.1rem',
            }}
          >
            FECHAR
          </p>
        </Button>
        <img src={modalImage} alt={modalImage} />
      </Modal>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default PresidenteAgenda;
