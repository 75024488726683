import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function Presidente() {
  const { setLoading } = useContext(AppContext);
  const [currentPresident, setCurrentPresident] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get(
          '/presidentes?search=[{"field":"current", "value":true}]',
        );
        setCurrentPresident(data.docs.length ? data.docs[0] : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setCurrentPresident('');
  }, []);

  if (!currentPresident || !currentPresident?.message) {
    return null;
  }

  return (
    <Row className="bg-gray-dark pt-5">
      <Col>
        <div
          className="d-flex justify-content-center justify-content-md-between align-items-center"
          style={{
            minHeight: isMobile ? '300px' : '700px',
            backgroundSize: 'contain',
            backgroundImage: `url(${currentPresident.image1_url})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 100%',
          }}
        >
          <div
            className="text-shadow text-white text-center mt-5 p-3 p-sm-5"
            style={{
              width: isMobile ? '100%' : '500px',
            }}
          >
            {HTMLReactParser(currentPresident?.message)}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Presidente;
