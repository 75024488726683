import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function NoticiasList() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const [noticias, setNoticias] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/noticias?page=${page}`);
        setNoticias(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setNoticias([]);
  }, [page]);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="FIQUE POR DENTRO DAS <br /> ÚLTIMAS NOTÍCIAS DA SAFL!" />
      <Container>
        {noticias.length > 0 ? (
          <Row className="mt-5">
            {noticias.map((n) => (
              <Col key={n._id} xs={12} sm={6} md={4}>
                <Card
                  className="shadow cursor-pointer"
                  style={{
                    height: '100%',
                    minHeight: '300px',
                    border: '0',
                    borderBottom: '3px solid #dddddd',
                  }}
                  onClick={() => navigate(`/noticia/${n._id}`)}
                >
                  <CardHeader className="text-primary">{n.title}</CardHeader>
                  <CardBody>
                    {n.description
                      ? HTMLReactParser(
                        n.description.split('<p>')[1]?.replace('</p>', ''),
                      )
                      : null}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">Nenhuma Notícia</strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default NoticiasList;
