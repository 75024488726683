import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import SaflTopBlue from '../../components/SaflTopBlue';

function FormularioProjetoEmenda() {
  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="FORMULÁRIO PARA <br /> PROJETO/EMENDA" />
      <Container>
        <Row className="px-2 py-2 py-sm-5">
          <Col>
            <div className="d-flex justify-content-center">
              <a
                href={encodeURI('https://api.safl.org.br/file-gallery/Formulario Projeto de Lei.docx')}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  className="p-2 px-4 text-white font-weight-500 zoom-105"
                  color="primary"
                  style={{
                    fontSize: '1.75rem',
                    border: '2px solid var(--white)',
                  }}
                >
                  Baixar Formulário &ensp;
                  <i className="fas fa-angle-double-right" />
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default FormularioProjetoEmenda;
