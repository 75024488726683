import React from 'react';
import ReactPlayer from 'react-player';

import './styles.css';

export default function ReactPlayerComponent({ url, width = '100%', height = '100%', ...props }) {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width={width}
        height={height}
        playing
        loop
        controls
        {...props}
      />
    </div>
  );
}
