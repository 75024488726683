import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function ReuniaoLink() {
  const { setLoading } = useContext(AppContext);
  const [reuniaoLink, setReuniaoLink] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get(
          '/reuniaoLinks?search=[{"field":"active", "value":true}]',
        );
        setReuniaoLink(data.docs.length ? data.docs[0] : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setReuniaoLink('');
  }, [setLoading]);

  if (!reuniaoLink) {
    return null;
  }

  return (
    <Row
      className="align-items-center p-5"
      style={{
        minHeight: '600px',
        background:
          'linear-gradient(45deg, #6D134E 10%, #384D93 60%, #1771BE 100%)',
      }}
    >
      <Col>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center">
              <h1 className="font-weight-700 text-shadow text-white text-center title-xl mt-5">
                Reunião de
                {' '}
                {format(new Date(reuniaoLink.date), "dd 'de' MMMM 'de' yyyy", {
                  locale: ptBR,
                })}
              </h1>
              <h3 className="font-weight-500 text-shadow text-white text-center">
                ID da Reunião
                {': '}
                {reuniaoLink.idLink}
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <a
                href={reuniaoLink.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  className="p-2 px-4 text-white font-weight-500 zoom-105"
                  color="primary"
                  style={{
                    fontSize: '1.75rem',
                    border: '2px solid var(--white)',
                  }}
                  outline
                >
                  Acesso a Reunião pelo Zoom &ensp;
                  <i className="fas fa-angle-double-right" />
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ReuniaoLink;
