import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Col, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function SessaoDinamicas({ sessaoDinamica }) {
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  if (sessaoDinamica?.image) {
    return (
      <Row
        className="align-items-center p-5"
        style={{
          minHeight: '600px',
          background: `linear-gradient(0deg, #777777 5%, ${sessaoDinamica?.backgroundColor} 60%, ${sessaoDinamica?.backgroundColor} 100%)`,
        }}
      >
        <Col xs={12} sm={6}>
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <img src={sessaoDinamica?.image_url} alt={sessaoDinamica?.image_url} width="100%" />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          {sessaoDinamica.title ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <h3 className="font-weight-700 text-shadow text-white text-center mt-5">
                    {sessaoDinamica.title}
                  </h3>
                </div>
              </Col>
            </Row>
          ) : null}
          {sessaoDinamica.text ? (
            <Row>
              <Col>
                <div className="text-justify">
                  {HTMLReactParser(sessaoDinamica.text)}
                </div>
              </Col>
            </Row>
          ) : null}
          {sessaoDinamica.link ? (
            <Row className="mt-5">
              <Col>
                <div className="d-flex justify-content-center">
                  <a
                    href={sessaoDinamica.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      className="p-2 px-4 text-white font-weight-500 zoom-105"
                      color="primary"
                      style={{
                        fontSize: '1.75rem',
                        border: '2px solid var(--white)',
                      }}
                      outline
                    >
                      Clique e Saiba mais
                      <i className="fas fa-angle-double-right" />
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }

  if (sessaoDinamica?.pdf) {
    return (
      <Row
        className="align-items-center p-5"
        style={{
          minHeight: '600px',
          background: `linear-gradient(0deg, #777777 5%, ${sessaoDinamica?.backgroundColor} 60%, ${sessaoDinamica?.backgroundColor} 100%)`,
        }}
      >
        <Col xs={12} sm={6}>
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <a
                    href={sessaoDinamica.file_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                      color="primary"
                      block
                    >
                      Abrir arquivo em nova aba &ensp;
                      <i className="fas fa-angle-double-right" />
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {pages && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between">
                        <Button
                          color="primary"
                          onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                        >
                          {'<'}
                        </Button>
                        <small>{`Página ${page} de ${pages}`}</small>
                        <Button
                          color="primary"
                          onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                        >
                          {'>'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
                <div className="d-flex justify-content-center">
                  <Document
                    file={sessaoDinamica.file_url}
                    error="Não foi possível carregar o pdf"
                    loading="Carregando Pdf"
                    onLoadSuccess={({ numPages }) => {
                      setPages(numPages);
                    }}
                  >
                    <Page pageNumber={page} />
                  </Document>
                </div>
                {pages && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between">
                        <Button
                          color="primary"
                          onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                        >
                          {'<'}
                        </Button>
                        <small>{`Página ${page} de ${pages}`}</small>
                        <Button
                          color="primary"
                          onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                        >
                          {'>'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          {sessaoDinamica.title ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <h3 className="font-weight-700 text-shadow text-white text-center mt-5">
                    {sessaoDinamica.title}
                  </h3>
                </div>
              </Col>
            </Row>
          ) : null}
          {sessaoDinamica.text ? (
            <Row>
              <Col>
                <div className="text-justify">
                  {HTMLReactParser(sessaoDinamica.text)}
                </div>
              </Col>
            </Row>
          ) : null}
          {sessaoDinamica.link ? (
            <Row className="mt-5">
              <Col>
                <div className="d-flex justify-content-center">
                  <a
                    href={sessaoDinamica.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      className="p-2 px-4 text-white font-weight-500 zoom-105"
                      color="primary"
                      style={{
                        fontSize: '1.75rem',
                        border: '2px solid var(--white)',
                      }}
                      outline
                    >
                      Clique e Saiba mais
                      <i className="fas fa-angle-double-right" />
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }

  return (
    <Row
      className="align-items-center p-5"
      style={{
        minHeight: '600px',
        background: `linear-gradient(0deg, #777777 5%, ${sessaoDinamica?.backgroundColor} 60%, ${sessaoDinamica?.backgroundColor} 100%)`,
      }}
    >
      <Col>
        {sessaoDinamica.title ? (
          <Row>
            <Col>
              <div className="d-flex justify-content-center">
                <h3 className="font-weight-700 text-shadow text-white text-center mt-5">
                  {sessaoDinamica.title}
                </h3>
              </div>
            </Col>
          </Row>
        ) : null}
        {sessaoDinamica.text ? (
          <Row>
            <Col>
              <div className="text-justify">
                {HTMLReactParser(sessaoDinamica.text)}
              </div>
            </Col>
          </Row>
        ) : null}
        {sessaoDinamica.link ? (
          <Row className="mt-5">
            <Col>
              <div className="d-flex justify-content-center">
                <a
                  href={sessaoDinamica.link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    className="p-2 px-4 text-white font-weight-500 zoom-105"
                    color="primary"
                    style={{
                      fontSize: '1.75rem',
                      border: '2px solid var(--white)',
                    }}
                    outline
                  >
                    Clique e Saiba mais
                    <i className="fas fa-angle-double-right" />
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
}

export default SessaoDinamicas;
