import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import Carousel from '../../components/Carousel';
import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function FotoGaleriasList() {
  const { setLoading } = useContext(AppContext);
  const [fotoGalerias, setFotoGalerias] = useState([]);
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/fotoGalerias?page=${page}`);
        setFotoGalerias(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setFotoGalerias([]);
  }, [page]);

  const handleViewImages = useCallback((id) => {
    (async () => {
      try {
        if (id) {
          setLoading(true);
          const { data } = await api.get(`/fotoGalerias/${id}`);
          setImages(data.images);
          setLoading(false);
          setShowModal(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="GALERIA DE FOTOS <br /> DA SAFL!" />
      <Container>
        {fotoGalerias.length > 0 ? (
          <Row className="mt-5">
            {fotoGalerias.map((g) => (
              <Col key={g._id} xs={12} sm={6} md={4}>
                <Card
                  className="shadow cursor-pointer"
                  style={{
                    height: '100%',
                    minHeight: '300px',
                    border: '0',
                    borderBottom: '3px solid #dddddd',
                  }}
                  onClick={() => handleViewImages(g._id)}
                >
                  {g.image ? (
                    <div
                      style={{
                        height: '200px',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${g.image_url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                  ) : null}
                  <CardBody>
                    <h3 className="text-primary">{g.title}</h3>
                    {g.description ? HTMLReactParser(g.description) : null}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">
                  Nenhuma Galeria de Foto
                </strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        className="position-relative"
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        fullscreen
      >
        <Button
          className="position-absolute top-3 right-3"
          style={{
            zIndex: 9999,
          }}
          color="link"
          onClick={() => setShowModal(false)}
        >
          <i
            className="fas fa-times text-white"
            style={{ fontSize: '1rem' }}
          />
          <p
            className="text-white"
            style={{
              fontSize: '0.9rem',
              lineHeight: '0.1rem',
            }}
          >
            FECHAR
          </p>
        </Button>
        {images && images?.length && <Carousel items={images} height="100vh" />}
      </Modal>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default FotoGaleriasList;
