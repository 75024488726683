import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'reactstrap';

import { format } from 'date-fns';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function Presidente() {
  const { setLoading } = useContext(AppContext);
  const [currentPresident, setCurrentPresident] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get(
          '/presidentes?search=[{"field":"current", "value":true}]',
        );
        setCurrentPresident(data.docs.length ? data.docs[0] : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setCurrentPresident('');
  }, []);

  if (!currentPresident) {
    return null;
  }

  return (
    <Row className="bg-gray pt-5">
      <Col>
        <div
          className="d-flex justify-content-center justify-content-md-between align-items-center"
          style={{
            height: isMobile ? '300px' : '700px',
            backgroundSize: 'contain',
            backgroundImage: `url(${currentPresident.image1_url})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 100%',
          }}
        >
          <h1 className="font-weight-700 text-shadow text-white text-center title-sm mt-5">
            NOVO PRESIDENTE DA SAFL
            {' '}
            <br />
            ELEITO PARA O MANDATO DE
            {' '}
            {currentPresident.startDate ? format(new Date(currentPresident.startDate), 'yyyy') : ''}
            /
            {currentPresident.endDate ? format(new Date(currentPresident.endDate), 'yyyy') : ''}
            {' '}
            <br />
            {currentPresident?.name?.toUpperCase()}
          </h1>
        </div>
      </Col>
    </Row>
  );
}

export default Presidente;
