/* eslint-disable max-len */
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Col, Row } from 'reactstrap';

import backgroundImage from '../../assets/img/gobimg.jpg';

function SobreNos() {
  return (
    <Row
      className="align-items-center p-5"
      style={{
        minHeight: '600px',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Col>
        <div className="d-flex justify-content-center justify-content-md-end align-items-center">
          <div
            className="text-shadow text-white text-center mt-5 p-3 p-sm-5"
            style={{
              width: isMobile ? '100%' : '500px',
            }}
          >
            <p className="text-justify">
              Embora tenha, a Maçonaria brasileira, se iniciado em 1797 com a
              Loja Cavaleiros da Luz, criada na povoação da Barra, em Salvador,
              Bahia, e ainda com a Loja União, em 1800, sucedida pela Loja
              Reunião em 1802, no Rio de Janeiro, só em 1822, quando a campanha
              pela independência do Brasil se tornava mais intensa, é que iria
              ser criada sua primeira Obediência, com Jurisdição nacional,
              exatamente com a incumbência de levar a cabo o processo de
              emancipação política do país.
            </p>

            <p className="text-justify">
              Criado a 17 de junho de 1822, por três Lojas do Rio de Janeiro – a
              Commercio e Artes na Idade do Ouro e mais a União e Tranquilidade
              e a Esperança de Niterói, resultantes da divisão da primeira – O
              Grande Oriente Brasileiro teve, como seus primeiros mandatários
              José Bonifácio de Andrada e Silva, ministro do Reino e de
              Estrangeiros e Joaquim Gonçalves Ledo, Primeiro Vigilante. A 4 de
              outubro do mesmo ano, já após a declaração de independência de 7
              de setembro, José Bonifácio foi substituído pelo então príncipe
              regente e, logo depois, Imperador D. Pedro I (Irmão Guatimozim).
            </p>

            <br />
            <a
              href="https://www.gob.org.br/historia-do-gob/"
              target="_blank"
              rel="noreferrer noopener"
              className="border w-100"
            >
              <Button color="primary" size="lg" block>
                LEIA MAIS
                <i className="fas fa-angle-double-right" />
              </Button>
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default SobreNos;
