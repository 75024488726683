import React from 'react';
import { Button, Col, Row } from 'reactstrap';

function ContatoLocalizacao({
  contact = true,
  location = true,
}) {
  return (
    <div id="contato">
      {contact ? (
        <Row className="mt-5">
          <Col xs={12} sm={6}>
            <a
              href="mailto:afl@gob.org.br"
              target="_blank"
              rel="noreferrer noopener"
              className="border w-100"
            >
              <Button color="primary" style={{ fontSize: '0.75rem' }} block>
                Clique e envie um email para
                {' : '}
                afl@gob.org.br
                {' '}
                <i className="fas fa-envelope" />
              </Button>
            </a>
          </Col>
          <Col xs={12} sm={6}>
            <a
              href="tel:61998087838"
              target="_blank"
              rel="noreferrer noopener"
              className="border w-100"
            >
              <Button color="primary" style={{ fontSize: '0.75rem' }} block>
                Ou ligue para
                {' : '}
                (61) 99808-7838
                {' '}
                <i className="fas fa-phone" />
              </Button>
            </a>
          </Col>
        </Row>
      ) : null}
      {location ? (
        <Row className="mt-3">
          <Col>
            <div
              style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                paddingTop: '56.25%',
              }}
            >
              <iframe
                title="Localização do GOB"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7677.363750732771!2d-47.924273395173564!3d-15.820719665859759!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdc5e968afa3728bb!2sGreat%20Orient%20free%20mason%20shop!5e0!3m2!1sen!2sus!4v1666706919337!5m2!1sen!2sus"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  border: 0,
                  width: '100%',
                  height: '100%',
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export default ContatoLocalizacao;
