import React from 'react';
import { Col, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import safltopblue from '../../assets/img/safltopblue.jpg';

function SaflTopBlue({ title }) {
  return (
    <Row
      className="align-items-center p-5"
      style={{
        height: '400px',
        backgroundSize: 'cover',
        backgroundImage: `url(${safltopblue})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Col>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center">
              <h1 className="font-weight-700 text-shadow text-white text-center title-xl mt-5">
                {HTMLReactParser(title)}
              </h1>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SaflTopBlue;
