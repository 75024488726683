import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import appimg from '../../assets/img/app.png';

function AppDownload() {
  return (
    <Row className="pt-2 pt-sm-5 flex-sm-row-reverse">
      <Col xs={12} sm={6}>
        <Row>
          <Col className="pt-5">
            <div className="pt-3 pt-sm-5 pr-3 pr-sm-5 pl-3">
              <p className="text-justify">
                O aplicativo do SISTEMA SAFL de votação está com cara nova. Mais
                moderno, intuitivo e com muito mais conteúdo relevante para o
                dia a dia dos nossos Deputados Federais.
              </p>

              <p className="text-justify">
                Baixe agora mesmo nas plataformas e fique atualizado com as
                novas tecnologias que a SAFL está proporcionando para você.
              </p>

              <p className="text-justify">
                Caso já tenha feito o download, apenas verifique se o aplicativo
                encontra-se atualizado.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="d-flex justify-content-center pb-2">
              <a
                href="https://apps.apple.com/br/app/sistema-safl/id1352309650"
                target="_blank"
                rel="noreferrer noopener"
                className="border w-100"
              >
                <Button color="light" style={{ fontSize: '0.75rem' }} block>
                  BAIXAR PARA IOS
                  {' | '}
                  <i className="fab fa-apple" />
                </Button>
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="d-flex justify-content-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.athos.sistemasafl&hl=pt_BR"
                target="_blank"
                rel="noreferrer noopener"
                className="border w-100"
              >
                <Button color="light" style={{ fontSize: '0.75rem' }} block>
                  BAIXAR PARA ANDROID
                  {' | '}
                  <i className="fab fa-android" />
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={6}>
        <div className="d-flex justify-content-center">
          <img src={appimg} alt="App SAFL" width="100%" />
        </div>
      </Col>
    </Row>
  );
}

export default AppDownload;
