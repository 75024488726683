import React, { useState, useEffect, useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Noticia() {
  const params = useParams();
  const { setLoading } = useContext(AppContext);
  const [noticia, setNoticia] = useState({});
  const [images, setImages] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        if (params.id) {
          setLoading(true);
          const { data } = await api.get(`/noticias/${params.id}`);
          setNoticia(data);
          setImages(data.files.filter((f) => {
            const mimeTypeSplit = f.mimetype.split('/');
            return mimeTypeSplit[0] === 'image';
          }));
          setPdfs(data.files.filter((f) => f.mimetype === 'application/pdf'));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setNoticia([]);
  }, [params.id]);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <Container>
        <Row className="mt-5">
          <Col>
            <Row className="mb-3">
              <Col>
                <h1>{noticia?.title}</h1>
              </Col>
            </Row>
            {noticia.image ? (
              <Row className="mb-5">
                <Col>
                  <div
                    style={{
                      height: '400px',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${noticia.image_url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                </Col>
              </Row>
            ) : null}
            {noticia.description ? (
              <Row className="mb-5">
                <Col>{HTMLReactParser(noticia.description)}</Col>
              </Row>
            ) : null}
            {pdfs.length ? (
              <Row className="mb-5">
                {pdfs.map((f) => (
                  <Col key={f._id}>
                    <div>
                      <Document
                        file={f.file_url}
                        error="Não foi possível carregar o pdf"
                        loading="Carregando Pdf"
                        onLoadSuccess={({ numPages }) => {
                          setPages(numPages);
                        }}
                      >
                        <Page pageNumber={page} />
                      </Document>
                    </div>
                    {pages && (
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-between">
                            <Button
                              color="primary"
                              onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                            >
                              {'<'}
                            </Button>
                            <small>{`Página ${page} de ${pages}`}</small>
                            <Button
                              color="primary"
                              onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                            >
                              {'>'}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-2">
                      <Col>
                        <a
                          href={f.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button color="success">
                            Download PDF &ensp;
                            <i className="fas fa-download" />
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            ) : null}
            {images.length ? (
              <Row className="mb-5">
                {images.map((i) => (
                  <Col key={i._id} xs={12} sm={6} className="mb-3">
                    <div
                      style={{
                        height: '400px',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${i.file_url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                  </Col>
                ))}
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default Noticia;
