import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

import logocompleto from '../../assets/img/logocompleto.png';

function Header() {
  const [togglerOpen, setTogglerOpen] = useState(!isMobile);

  return (
    <Row className="bg-white px-2 py-2 px-sm-4 align-items-center">
      <Col xs={12} lg={5}>
        <Row className="align-items-center">
          <Col xs={10} md={12}>
            <Link to="/">
              <img src={logocompleto} alt="SAFL | SISTEMA SAFL" width="100%" />
            </Link>
          </Col>
          <Col xs={2} className="d-md-none">
            <Button
              color="link"
              onClick={() => setTogglerOpen((prevState) => !prevState)}
            >
              <i className="fas fa-bars text-primary" />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={7} className={`${togglerOpen ? '' : 'd-none'}`}>
        <Row>
          <Col>
            <div className="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
              <Button color="link">
                <Link to="/">
                  Início
                </Link>
              </Button>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="link" caret>
                  Institucional
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <Link to="/presidente-agendas">Agenda do Presidente</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/formulario-projeto-emenda">
                      Formulário para Emenda/Projeto
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/presidente-palavras">Palavra do Presidente</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="link" caret>
                  Eventos
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <Link to="/aniversario-de-lojas">Aniversário de Lojas</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/noticias">Notícias</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="link" caret>
                  Galeria
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <Link to="/ex-presidentes">Ex-Presidentes</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/galeria-de-fotos">Fotos</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/galeria-de-videos">Vídeos</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle size="sm" color="link" caret>
                  Links
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    href="https://www.gob.org.br/acao-paramaconica-juvenil/"
                    target="_blank"
                  >
                    APJ
                  </DropdownItem>
                  <DropdownItem
                    href="https://www.facebook.com/groups/afl.gob"
                    target="_blank"
                  >
                    Facebook
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button
                className="m-2 text-primary"
                size="sm"
                color="link"
                onClick={() => {
                  const id = 'contato';
                  const yOffset = -100;
                  const element = document.getElementById(id);
                  const y = element.getBoundingClientRect().top
                    + window.pageYOffset
                    + yOffset;

                  window.scrollTo({ top: y, behavior: 'smooth' });
                }}
              >
                Contato
              </Button>
              <a
                href={process.env.REACT_APP_PORTAL_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button className="m-2 text-primary" size="sm" color="link">
                  Acesso Restrito
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
