/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Row } from 'reactstrap';

import Header from '../Header';
import './styles.css';

function HeaderFixed() {
  useEffect(() => {
    const toggleNavbar = () => {
      const elem = document.getElementById('navbar-top-fixed');
      if (elem) {
        if (
          document.body.scrollTop > 150
          || document.documentElement.scrollTop > 150
        ) {
          elem.style.top = '0';
        } else {
          elem.style.top = '-220px';
        }
      }
    };
    document.addEventListener('scroll', toggleNavbar, false);

    return () => document.removeEventListener('scroll', toggleNavbar, false);
  }, []);

  return (
    <Row
      id="navbar-top-fixed"
    >
      <Header />
    </Row>
  );
}

export default HeaderFixed;
