import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import backgroundImage from '../../assets/img/luto.png';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function NotaDePesar() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const [notaDePesar, setNotaDePesar] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get(
          '/notaDePesars?search=[{"field":"active", "value":true}]',
        );
        setNotaDePesar(data.docs.length ? data.docs[0] : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setNotaDePesar('');
  }, []);

  if (!notaDePesar) {
    return null;
  }

  return (
    <Row
      className="align-items-center p-5"
      style={{
        height: '600px',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Col>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center">
              <h1 className="font-weight-700 text-shadow text-white text-center title-xl mt-5">
                NOTA DE PESAR
              </h1>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <a
                href={notaDePesar.pdf_url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  className="p-2 px-4 text-white font-weight-500 zoom-105"
                  color="primary"
                  style={{
                    fontSize: '1.75rem',
                    border: '2px solid var(--white)',
                  }}
                  outline
                >
                  Conferir &ensp;
                  <i className="fas fa-angle-double-right" />
                </Button>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <Button onClick={() => navigate('/notas-de-pesar')}>
                Veja mais
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NotaDePesar;
