/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'reactstrap';

import Carousel from '../../components/Carousel';
import Comunicado from '../../components/Comunicado';
import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Empossado from '../../components/Empossado';
import Enquete from '../../components/Enquete';
import Footer from '../../components/Footer';
import GoToTop from '../../components/GoToTop';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Informativo from '../../components/Informativo';
import InformativoImportante from '../../components/InformativoImportante';
import LojasHomologada from '../../components/LojaHomologada';
import NotaDePesar from '../../components/NotaDePesar';
import Presidente from '../../components/Presidente';
import PresidentePalavra from '../../components/PresidentePalavra';
import ReuniaoLink from '../../components/ReuniaoLink';
import SessaoDinamicas from '../../components/SessaoDinamicas';
import SobreNos from '../../components/SobreNos';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import AppDownload from '../AppDownload';

export default function Home() {
  const { setLoading } = useContext(AppContext);
  const [sliders, setSliders] = useState([]);
  const [sessaoDinamicas, setSessaoDinamicas] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/sliders');
        setSliders(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setSliders([]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/sessaoDinamicas?search=[{"field":"active", "value":true}]');
        setSessaoDinamicas(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setSessaoDinamicas([]);
  }, []);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      {sliders && sliders.length ? <Carousel items={sliders} /> : null}
      <Presidente />
      {sessaoDinamicas.map((s) => (
        <SessaoDinamicas key={s._id} sessaoDinamica={s} />
      ))}
      <Informativo />
      <ReuniaoLink />
      <Empossado />
      <NotaDePesar />
      <LojasHomologada />
      <Enquete />
      <InformativoImportante />
      <Comunicado />
      <AppDownload />
      <PresidentePalavra />
      <SobreNos />
      <ContatoLocalizacao />
      <Footer />
      <GoToTop />
    </Container>
  );
}
