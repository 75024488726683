import React, { useState, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function PresidenteAgenda() {
  const { setLoading } = useContext(AppContext);
  const [agendas, setAgendas] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/presidenteAgendas');
        setAgendas(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setAgendas([]);
  }, []);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="AGENDA DO <br /> PRESIDENTE" />
      <Container>
        {agendas.length > 0 ? (
          agendas.map((a, i) => (
            <Row
              key={a._id}
              className={`p-2 p-sm-4 mt-5 ${i % 2 === 0 ? 'bg-primary' : 'bg-danger'}`}
              style={{
                borderRadius: '1rem',
              }}
            >
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h3 className="text-white">{format(new Date(a.date), 'dd/MM/yyyy', ptBR)}</h3>
                  <strong className="text-white mt-2">{a.description}</strong>
                </div>
              </Col>
            </Row>
          ))) : (
            <Row
              className="p-2 p-sm-4 mt-5 bg-primary"
              style={{
                borderRadius: '1rem',
              }}
            >
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <strong className="text-white mt-2">Nenhuma agenda para o presidente</strong>
                </div>
              </Col>
            </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default PresidenteAgenda;
