import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
  Container,
  CardHeader,
} from 'reactstrap';

import * as Yup from 'yup';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Notification from '../../components/Notification';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

function LoginAdmin() {
  const navigate = useNavigate();
  const notificationRef = useRef();
  const { signInAdmin } = useAuth();
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');

  const handleSubmit = () => {
    (async () => {
      try {
        const data = {
          email,
          password,
        };

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          password: Yup.string().required('Informe uma senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const res = await api.post('/loginAdmins', data);
        const { token, user } = res.data;

        notificationRef.current.notify({
          message: 'Login realizado com sucesso',
        });

        setTimeout(() => {
          signInAdmin(token, user);
          navigate('/admin');
        }, 2000);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar o login para ter acesso a sua conta',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar LoginAdmin',
          color: 'danger',
        });
      }
    })();
  };

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Header addBackHome />
      <Row className="justify-content-center px-2 py-3 p-sm-5">
        <Col xs="12" sm="8" md="6">
          <Card className="p-0 p-md-3">
            <CardHeader className="bg-secondary">
              <h6>Login da Área Administrativa</h6>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.email ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      type="email"
                      placeholder="Email"
                      value={email}
                      invalid={!!validationErrors.email}
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.email}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.password ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      type={passwordType}
                      placeholder="Senha"
                      value={password}
                      invalid={!!validationErrors.password}
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                    <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                      <i
                        className={`fas ${
                          passwordType === 'password'
                            ? 'fa-eye-slash'
                            : 'fa-eye'
                        }`}
                      />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.password}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <Row>
                  <Col>
                    <Button color="primary" onClick={handleSubmit} block>
                      Login Admin
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default LoginAdmin;
