import AniversarioLojasList from '../pages/AniversarioLojasList';
import ComunicadosList from '../pages/ComunicadosList';
import EnquetesList from '../pages/EnquetesList';
import ExPresidenteList from '../pages/ExPresidenteList';
import FormularioProjetoEmenda from '../pages/FormularioProjetoEmenda';
import FotoGaleriaList from '../pages/FotoGaleriasList';
import Home from '../pages/Home';
import InformativosImportantesList from '../pages/InformativosImportantesList';
import InformativosList from '../pages/InformativosList';
import LoginAdmin from '../pages/LoginAdmin';
import NotaDePesarsList from '../pages/NotaDePesarsList';
import Noticia from '../pages/Noticia';
import NoticiasList from '../pages/NoticiasList';
import PresidenteAgenda from '../pages/PresidenteAgenda';
import PresidentePalavra from '../pages/PresidentePalavra';
import VideoList from '../pages/VideoList';

export default [
  { path: '/', Component: Home },
  { path: '/loginAdmin', Component: LoginAdmin },
  { path: '/presidente-agendas', Component: PresidenteAgenda },
  { path: '/presidente-palavras', Component: PresidentePalavra },
  { path: '/formulario-projeto-emenda', Component: FormularioProjetoEmenda },
  { path: '/aniversario-de-lojas', Component: AniversarioLojasList },
  { path: '/noticias', Component: NoticiasList },
  { path: '/noticia/:id', Component: Noticia },
  { path: '/galeria-de-fotos', Component: FotoGaleriaList },
  { path: '/galeria-de-videos', Component: VideoList },
  { path: '/ex-presidentes', Component: ExPresidenteList },
  { path: '/informativos', Component: InformativosList },
  { path: '/informativos-importantes', Component: InformativosImportantesList },
  { path: '/notas-de-pesar', Component: NotaDePesarsList },
  { path: '/enquetes', Component: EnquetesList },
  { path: '/comunicados', Component: ComunicadosList },
  { path: '*', Component: Home },
];
