import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import htmlReactParser from 'html-react-parser';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import ReactPlayerComponent from '../ReactPlayer';

function Enquete() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const [enquete, setEnquete] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get(
          '/enquetes?search=[{"field":"active", "value":true}]',
        );
        setEnquete(data.docs.length ? data.docs[0] : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setEnquete('');
  }, []);

  if (!enquete) {
    return null;
  }

  return (
    <Row
      className="align-items-center p-5"
      style={{
        minHeight: '600px',
        backgroundColor: '#9EA499',
      }}
    >
      <Col>
        <Row>
          <Col xs={12} sm={6}>
            <div className="d-flex justify-content-end">
              <h1 className="font-weight-700 text-shadow text-white text-center title-sm mt-5">
                {htmlReactParser(enquete.title)}
              </h1>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <ReactPlayerComponent url={enquete.url} playing={false} />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <Button onClick={() => navigate('/enquetes')}>
                Veja mais Enquetes
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Enquete;
