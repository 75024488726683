import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row,
} from 'reactstrap';

import htmlReactParser from 'html-react-parser';

function CarouselComponent({
  items,
  initialActiveIndex = 0,
  height = '500px',
}) {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setActiveIndex(initialActiveIndex);
  }, [initialActiveIndex]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item._id}
    >
      <div
        style={{
          width: '100%',
          height,
          backgroundSize: 'cover',
          backgroundImage: `url(${
            item.image_url || item.file_url || item.url
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: '50%',
          backgroundPositionY: '50%',
        }}
      />
      {item.title ? (
        <div className="d-flex w-100 h-100 position-absolute top-0 justify-content-center align-items-center">
          <h1 className="font-weight-900 text-white text-center title-sm">
            {htmlReactParser(item.title)}
          </h1>
        </div>
      ) : null}
    </CarouselItem>
  ));

  return (
    <Row>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="w-100"
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="."
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="."
          onClickHandler={next}
        />
      </Carousel>
    </Row>
  );
}

export default CarouselComponent;
