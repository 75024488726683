import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import ReactPlayerComponent from '../../components/ReactPlayer';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function InformativosList() {
  const { setLoading } = useContext(AppContext);
  const [informativos, setInformativos] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/informativos?page=${page}`);
        setInformativos(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setInformativos([]);
  }, [page]);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="INFORMATIVOS" />
      <Container>
        {informativos.length > 0 ? (
          <Row className="mt-5">
            {informativos.map((i) => (
              <Col key={i._id} xs={12} sm={6} md={4}>
                <Card
                  className="shadow"
                  style={{
                    height: '100%',
                    minHeight: '300px',
                    border: '0',
                    borderBottom: '3px solid #dddddd',
                  }}
                >
                  <ReactPlayerComponent url={i.url} playing={false} />
                  <CardBody>
                    <h3>{i.title}</h3>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">Nenhum Informativo</strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default InformativosList;
