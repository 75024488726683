import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function NotaDePesarsList() {
  const { setLoading } = useContext(AppContext);
  const [notaDePesars, setNotaDePesars] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/notaDePesars?page=${page}`);
        setNotaDePesars(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setNotaDePesars([]);
  }, [page]);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="NOTAS DE PESAR" />
      <Container>
        {notaDePesars.length > 0 ? (
          <Row className="mt-5">
            {notaDePesars.map((n) => (
              <Col key={n._id} xs={12} sm={6} md={4}>
                <Card
                  className="shadow cursor-pointer"
                  style={{
                    height: '100%',
                    border: '0',
                    borderBottom: '3px solid #dddddd',
                  }}
                >
                  <CardBody>
                    <a
                      href={n.pdf_url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Button
                        className="p-2 px-4 text-white font-weight-500 zoom-105"
                        color="primary"
                        style={{
                          fontSize: '1.75rem',
                          border: '2px solid var(--white)',
                        }}
                        block
                      >
                        Conferir &ensp;
                        <i className="fas fa-angle-double-right" />
                      </Button>
                    </a>
                    <div className="d-flex flex-column justify-content-center text-center">
                      <strong>{n.pdfname}</strong>
                      <span>{format(new Date(n.createdAt), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">Nenhuma Nota de Pesar</strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default NotaDePesarsList;
