import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Input, Row } from 'reactstrap';

import { format, getMonth, getYear, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import ContatoLocalizacao from '../../components/ContatoLocalizacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderFixed from '../../components/HeaderFixed';
import Pagination from '../../components/Pagination';
import SaflTopBlue from '../../components/SaflTopBlue';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

function AniversarioLojasList() {
  const { setLoading } = useContext(AppContext);
  const [aniversarioLojas, setAniversarioLojas] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [mes, setMes] = useState(0);

  useEffect(() => {
    setMes(getMonth(new Date()));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/aniversarioLojas?page=${page}&search=[{"field":"mes", "value":${mes}}]`);
        setAniversarioLojas(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setAniversarioLojas([]);
  }, [page, mes]);

  return (
    <Container fluid>
      <Header />
      <HeaderFixed />
      <SaflTopBlue title="ANIVERSÁRIO DE LOJAS" />
      <Container>
        <Row className="mt-5">
          <Col>
            <FormGroup>
              <Input
                type="select"
                value={mes}
                onChange={({ target: { value } }) => setMes(value)}
              >
                <option label="Janeiro" value={0} />
                <option label="Fevereiro" value={1} />
                <option label="Março" value={2} />
                <option label="Abril" value={3} />
                <option label="Maio" value={4} />
                <option label="Junho" value={5} />
                <option label="Julho" value={6} />
                <option label="Agosto" value={7} />
                <option label="Setembro" value={8} />
                <option label="Outubro" value={9} />
                <option label="Novembro" value={10} />
                <option label="Dezembro" value={11} />
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {aniversarioLojas.length > 0 ? (
          <Row className="mt-5">
            {aniversarioLojas.map((a) => (
              <Col key={a._id} xs={12} sm={6} md={4}>
                <Card
                  className="shadow cursor-pointer"
                  style={{
                    height: '100%',
                    border: '0',
                    borderBottom: '3px solid #dddddd',
                  }}
                >
                  <CardBody>
                    <div className="d-flex flex-column justify-content-center">
                      <h4>{`${a.nome} - ${a.uf}`}</h4>
                      <div
                        className="mt-3 p-2 border"
                        style={{ borderRadius: '0.6rem' }}
                      >
                        <Row>
                          <Col>
                            {format(
                              new Date(getYear(new Date()), a.mes, a.dia),
                              "dd 'de' MMMM ' - ' iiii",
                              { locale: ptBR },
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {`${
                              getYear(new Date())
                                - getYear(parseISO(a.fundacao))
                            } ano(s)`}
                          </Col>
                        </Row>
                        <Row>
                          <Col>{`Responsável: ${a.responsavel}`}</Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row
            className="p-2 p-sm-4 mt-5 bg-primary"
            style={{
              borderRadius: '1rem',
            }}
          >
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong className="text-white mt-2">
                  Nenhum Aniversário de Loja
                </strong>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ContatoLocalizacao location={false} />
      <Footer />
    </Container>
  );
}

export default AniversarioLojasList;
